import * as React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

type NoResultProps = {
  message?: string;
  imageUrl?: string;
  size?: number,
};

const NoResultWrapper = styled.div`
  text-align:  center;
  padding-bottom: 1.5rem;
`;

const NoResultText = styled.div`
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1;
`;

const NoResult: React.FunctionComponent<NoResultProps> = ({
  message,
  imageUrl,
  size,
}) => {
  return (
    <NoResultWrapper>
      <Image
        width={size || 190}
        height={size || 190}
        priority
        layout="intrinsic"
        src={imageUrl || '/images/no-result.svg'}
        alt="no-result"
      />
      <NoResultText>{(!isEmpty(message) || message === '') ? message : 'ไม่พบรายการ'}</NoResultText>
    </NoResultWrapper>
  );
};

export default NoResult;
