import { atom } from 'recoil';

import { KEYS } from '../keys';

export const initialValue = undefined;

const coinState = atom<string | undefined>({
  key: KEYS.COIN,
  default: initialValue,
});

export default coinState;
