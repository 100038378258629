import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  BUTTON_TYPE,
} from '@/components/Button';
import useSystemStatus from '@/hooks/useSystemStatus';
import { getLineWebLoginUrl, SCOPE_ENUM } from '@/services/line';
import { COOKIES_KEY, useCookies } from '@/utils/cookies';
import { IMAGE_FOLDER_PATH } from '@/models/buildtime-constant';
import { ButtonProps } from '../CommonButton/CommonButton';
import { StyledLineButton } from '../button.style';

export type ILineButtonProps = ButtonProps & {
  redirectPath: string;
};

const LineButton: React.FunctionComponent<ILineButtonProps> = (props) => {
  const { redirectPath } = props;
  const router = useRouter();
  const { t } = useTranslation(['login']);
  const { refreshSystemStatus } = useSystemStatus();
  const [, setCookies] = useCookies<string>();

  const checkLineHandler = async () => {
    await refreshSystemStatus();
    const loginState = Buffer.from(Math.random().toString()).toString('base64').substring(10, 5);
    const nonce = Buffer.from(Math.random().toString()).toString('base64').substring(10, 5);
    const scope = [SCOPE_ENUM.PROFILE, SCOPE_ENUM.OPENID];
    setCookies(COOKIES_KEY.LINE_STATE, loginState);
    router.replace(getLineWebLoginUrl(`${window.location.origin}${redirectPath}`, loginState, nonce, scope));
  };

  return (
    <StyledLineButton
      {...props}
      id="checkline-next-button"
      testId="login-line-button"
      type={BUTTON_TYPE.OUTLINE}
      onClick={checkLineHandler}
      nativeType="submit"
      prependContent={(<img alt="Login_with_LINE" src={`${IMAGE_FOLDER_PATH}/Line-Logo.svg`} className="ps-login__line-logo" />)}
      wrapperStyle={{
        ...props.wrapperStyle,
        borderWidth: '1',
        display: 'flex',
        border: '1px solid',
      }}
    >
      {t`login:checkPhone.linelogin`}
    </StyledLineButton>
  );
};

export default LineButton;
