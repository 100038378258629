import axios from 'axios';
import Cookies from 'js-cookie';
import {
  API_URL,
  BO_API_URL,
  PIGSPIN_API_URL,
  QR_PAYMENT_API_URL,
} from '@/models/buildtime-constant';

export const phpApiInstance = axios.create({
  baseURL: API_URL,
  // timeout: 5000,
});

export const boApiInstance = axios.create({
  baseURL: BO_API_URL,
});

export const pigspinApiInstance = axios.create({
  baseURL: PIGSPIN_API_URL,
  // timeout: 5000,
});
pigspinApiInstance.interceptors.request.use(
  (config) => {
    const authToken = Cookies.get('cfid');
    if (authToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.authorisation = `${authToken}`.replace(/"/g, '').trim();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
pigspinApiInstance.interceptors.response.use((response) => {
  return response;
}, (error: any) => {
  return error.response;
});

export const qrPaymentApiInstance = axios.create({
  baseURL: QR_PAYMENT_API_URL,
  // timeout: 7500,
});

export const lineApiInstance = axios.create({
  baseURL: 'https://api.line.me',
  timeout: 8000,
});
