import { atom } from 'recoil';
import { BigWinChampionDetail } from '@/services/bigWin/getBigWinChampionList.type';
import { KEYS } from '../keys';

export const initialValue: BigWinChampionDetail | null = null;

const incomingBigWinState = atom<BigWinChampionDetail | null>({
  key: KEYS.BIGWIN,
  default: initialValue,
});

export default incomingBigWinState;
