import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { KEYS } from '../keys';

const { persistAtom } = recoilPersist();

export type FilterConfigState = {
};

export const initialValue: FilterConfigState = {};

const filterConfigState = atom<FilterConfigState>({
  key: KEYS.FILTER_CONFIG,
  default: initialValue,
  effects_UNSTABLE: [persistAtom],
});

export default filterConfigState;
