import { atom } from 'recoil';

import { KEYS } from '../keys';

export type BackButtonState = {
  onBack: (() => void) | null,
};

export const initialValue = undefined;

const backButtonState = atom<BackButtonState>({
  key: KEYS.BACK_BUTTON,
  default: {
    onBack: null,
  },
});

export default backButtonState;
