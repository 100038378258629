import { selector } from 'recoil';
import { getInboundBalance, getInboundTotalBalance } from '@/utils/wallet';
import walletState from './modules/wallet';

const walletSelectorState = selector({
  key: 'walletSelectorState',
  set: ({ set }, newWallet: any) => {
    const balance = getInboundBalance(newWallet?.balance);
    const coin = getInboundBalance(newWallet?.coin);
    const total = getInboundTotalBalance(newWallet?.balance, newWallet?.coin);
    const validateWallet = {
      total,
      balance,
      coin,
    };
    set(walletState, validateWallet);
  },
  get: ({ get }) => {
    const wallet = get(walletState);
    const walletTotal = wallet?.total;
    const walletBalance = wallet?.balance;
    const walletCoin = wallet?.coin;

    return {
      walletTotal,
      walletBalance,
      walletCoin,
    };
  },
});

export default walletSelectorState;
