import { atom } from 'recoil';

import { KEYS } from '../keys';

type RegisterState = {
  phoneNumber: string,
  otpCode: string,
  otpToken: string,
  pin: string,
  isError: boolean,
  errorMessage?: string,
  isReferrerValid?: boolean;
};

export const initialValue: RegisterState = {
  phoneNumber: '',
  otpCode: '',
  otpToken: '',
  pin: '',
  isError: false,
  errorMessage: undefined,
  isReferrerValid: true,
};

const registerState = atom<Partial<RegisterState>>({
  key: KEYS.REGISTER,
  default: initialValue,
});

export default registerState;
