import {
  format,
  parseISO,
  getYear,
  isValid,
} from 'date-fns';
import dayjs from 'dayjs';
import { numberWithoutCommas } from './number';

export const dateFormatConverter = (datetimeBE: string): string => {
  const isDateValid = dayjs(datetimeBE).isValid();
  if (!isDateValid) return '';
  const datetimeCE = (parseInt(datetimeBE.split('-')[0])) + datetimeBE.substring(4);
  return format(parseISO(datetimeCE), 'd/M/yyyy');
};

export const dateBeFormatConverter = (datetimeBE: string): string => {
  const [year, month, day] = datetimeBE.split('-');
  const noMonth = month === '00';
  const noDate = day === '00';
  if (noMonth || noDate) {
    return `${day}/${month}/${numberWithoutCommas(year) + 543}`;
  }
  const date = new Date(datetimeBE);
  if (!isValid(date)) return '';
  return `${format(date, 'dd')}/${format(date, 'MM')}/${getYear(date) + 543}`;
};

// TODO : refactor soon
// this function is a little bit weird because we can send any value as dateStr
// ex, forceDateToISO("dummy dateStr yahoo") => Invalid Date
// we should prevent that dateStr is a valid date

export const forceDateToISO = (dateStr: string): Date => {
  let result = dateStr.toLocaleUpperCase();
  if (result.slice(-1) !== 'Z') {
    result += 'Z';
  }
  return new Date(result);
};

export const THAI_SHORT_MONTHS: string[] = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
];
