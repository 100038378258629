import React from 'react';
import { BaseButtonProps } from '../Button.type';

export type IconButtonProps = BaseButtonProps & {
  icon?: string;
};

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(({
  id = 'button',
  onClick,
  disabled,
  className,
  icon = 'check-filled',
}, buttonRef) => {
  return (
    <button
      data-testid="icon-button"
      id={id}
      onClick={onClick}
      disabled={disabled}
      ref={buttonRef}
      type="button"
      className={`ps-icon-button ${disabled ? 'ps-icon-button--disabled' : ''} ${className}`}
    >
      <i className={`ps-icon-${icon}`} />
    </button>
  );
});

IconButton.displayName = 'IconButton';

export default IconButton;
