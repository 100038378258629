import { atom } from 'recoil';

import { KEYS } from '../keys';
import { VipProfileData, emptyVip } from '@/components/Vip/type';

export type VipState = {
  vip: VipProfileData;
};

export const initialValue: VipState = {
  vip: emptyVip,
};

const vipState = atom<VipState>({
  key: KEYS.VIP,
  default: initialValue,
});

export default vipState;
