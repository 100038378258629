import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';

import { useMemo, useEffect } from 'react';
import Head from 'next/head';
import TagManager from 'react-gtm-module';
import '@/styles/scss/main.scss';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import th from 'dayjs/locale/th';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorComponant } from '@/components/Error';
import { WEB_URL, GTM_TRACKING_ID } from '@/models/buildtime-constant';
import { COOKIES_KEY, useCookies, UtmCookiesType } from '@/utils/cookies';
import { UTM } from '@/hooks/useRegister/type';
import font from '@/utils/font';
import logError from '@/utils/logError';

const Layout = dynamic(() => import('@/components/Layout'), { ssr: false });
const AppProvider = dynamic(() => import('@/context/Provider'), { ssr: false });
const Toast = dynamic(() => import('@/components/Toast/Toast'), { ssr: false });
const AppProgressBar = dynamic(() => import('@/components/AppProgressBar'), { ssr: false });

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [, setCookies] = useCookies<UtmCookiesType>([
    COOKIES_KEY.UTM,
    COOKIES_KEY.CFID,
  ]);
  dayjs.locale(th);
  dayjs.extend(duration);
  dayjs.extend(updateLocale);
  dayjs.extend(relativeTime);
  const canonicalUrl = WEB_URL.toLowerCase() + (router.pathname.substring(1));
  const ComponentPage = useMemo(() => {
    if (router.pathname === '/authenticate' || router.pathname.includes('/logout')) {
      return <Component {...pageProps} />;
    }
    return (
      <Layout>
        <Component {...pageProps} />
      </Layout>
    );
  }, [router.pathname]);

  // init GTM only on client side
  useEffect(() => {
    if (typeof window !== 'undefined') {
      TagManager.initialize({ gtmId: `${GTM_TRACKING_ID}` });

      // NOTE : Collect UTM parameters
      const { query } = router;
      const { protocol, hostname, pathname } = window.location;
      const landingUTM: UTM = {};
      const keyMapper = Object.keys(query) as [keyof UTM];
      const queryValues = Object.values(query);
      keyMapper.forEach((k, i) => {
        if (Array.isArray(queryValues[i])) {
          const [tmp] = queryValues[i] as string[];
          landingUTM[k] = tmp;
          return;
        }
        landingUTM[k] = queryValues[i] as string;
      });

      setCookies(COOKIES_KEY.UTM, {
        firstVisitUrl: `${protocol}//${hostname}${pathname}`,
        utmId: landingUTM.utm_id,
        utmSource: landingUTM.utm_source,
        utmMedium: landingUTM.utm_medium,
        utmCampaign: landingUTM.utm_campaign,
        utmTerm: landingUTM.utm_term,
        utmContent: landingUTM.utm_content,
      });
    }
  }, []);

  return (
    <div className={font.className}>
      <Head>
        <title>สล็อตออนไลน์ เว็บสล็อตที่มาแรงที่สุด สล็อตเว็บตรงpgออนไลน์ PIGSPIN</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=0"
        />
      </Head>

      <ErrorBoundary
        FallbackComponent={ErrorComponant}
        onError={logError}
      >
        <AppProvider>
          <AppProgressBar />
          {ComponentPage}
        </AppProvider>
      </ErrorBoundary>
      <Toast />
      {/* modal wrapper */}
      <div id="modal-root" />
      {/* landscape orientation wrapper */}
      <div id="landscape-orientation-root" />
    </div>
  );
}

export default appWithTranslation(MyApp);
