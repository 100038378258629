import { atom } from 'recoil';

import { KEYS } from '../keys';
import { Notifications } from '@/hooks/useNotification';

export const initialValue: Notifications = {
  dailyQuest: false,
};

const notificationState = atom<Notifications>({
  key: KEYS.NOTIFICATION,
  default: initialValue,
});

export default notificationState;
