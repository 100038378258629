import { pigspinApiInstance } from '@/utils/api/AxiosInstance';
import { BaseApiResponse } from '@/services/service.type';
import { fetchWithJSON } from '@/utils/api/fetch';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VerifyResultData {
}

export const revokeToken = async (token: string): Promise<VerifyResultData | null> => {
  try {
    const response = await fetchWithJSON<BaseApiResponse<VerifyResultData>>(pigspinApiInstance, {
      url: '/v1/user/token/revoke',
      headers: {
        authorisation: token,
      },
      method: 'PUT',
    });
    const result: VerifyResultData = response.data;
    return result;
  } catch (error) {
    return null;
  }
};
