import { GameItem } from '../games';
import { AxiosRequestConfig } from 'axios';
import { fetchWithJSON } from '@/utils/api/fetch';
import { pigspinApiInstance } from '@/utils/api/AxiosInstance';
import { FavoriteGameResponse } from './favorite.type';

const callFavoriteGame = async (customerCode: string): Promise<GameItem[]> => {
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/game/game/${customerCode}/favorite`,
    method: 'POST',
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  };
  const favoriteGameResponse = await fetchWithJSON<FavoriteGameResponse>(
    pigspinApiInstance,
    requestConfig,
  );
  return favoriteGameResponse.data || [];
};

export default callFavoriteGame;
