import { useRecoilState } from 'recoil';
import parse from 'html-react-parser';
import { useInfiniteQuery, useMutation } from 'react-query';

import {
  useMemo,
} from 'react';
import {
  inboxAffiliateState,
  inboxTransactionState,
  InboxItem,
  inboxSystemStatusState,
  defaultInboxState,
} from '@/recoils/modules/inbox';
import { InboxMessage, mapToInboxItem, mapToInboxMessage } from '@/mappers/mapToInboxItem';
import { useBalance } from './useBalance';
import toast from '@/utils/toast';
import { useAxios } from './useAxios';
import { fetchWithJSON } from '@/utils/api/fetch';
import { CoinWalletResultType } from '@/recoils/type';
import bonusState from '@/recoils/modules/bonus';
import { BigWinChampionDetail } from '@/services/bigWin/getBigWinChampionList';
import { useAuth } from './useAuth';
import { useSocket } from '@/context/Provider/Socket';
import { SystemStatusResponse } from './useSystemStatus/type';
import incomingBigWinState from '@/recoils/modules/bigwin';
import isEmpty from 'lodash/isEmpty';

export type CategorisedInbox = {
  system: InboxItem[];
  affiliate: InboxItem[];
  transaction: InboxItem[];
};

export type BonusEvent = {
  activeBonus: CoinWalletResultType[];
  completedBonus: CoinWalletResultType[];
};

export const inboxSelector = (inboxList: InboxItem[]): CategorisedInbox => {
  return {
    system: inboxList.filter((i) => i.type === 'system'),
    affiliate: inboxList.filter((i) => i.type === 'affiliate'),
    transaction: inboxList.filter((i) => i.type === 'transaction'),
  };
};

const updateInboxs = (currentInboxList: InboxItem[], updateInboxList: InboxItem[]): InboxItem[] => {
  return currentInboxList.map((currentInbox) => {
    const updatingInbox = updateInboxList.find((inbox) => inbox.uid === currentInbox.uid);
    if (updatingInbox) return updatingInbox;
    return currentInbox;
  });
};

const insertInboxs = (currentInboxList: InboxItem[], insertInboxList: InboxItem[]): InboxItem[] => {
  let result = currentInboxList;
  insertInboxList.forEach((insertingInbox) => {
    const existingInsertInbox = result.find((inbox) => inbox.uid === insertingInbox.uid);
    if (!existingInsertInbox) {
      result = [insertingInbox, ...result];
    }
  });
  return result;
};
const deleteInboxs = (currentInboxList: InboxItem[], insertInboxList: InboxItem[]): InboxItem[] => {
  const existingInsertInbox: InboxItem | undefined = insertInboxList[0];
  return currentInboxList.filter((inbox) => existingInsertInbox && inbox.uid !== existingInsertInbox.uid);
};

export const insertAndUpdateInboxs = (currentInboxList: InboxItem[], incomingInboxList: InboxItem[]): InboxItem[] => {
  const insertedInboxs = insertInboxs(currentInboxList, incomingInboxList);
  const updatedInboxs = updateInboxs(insertedInboxs, incomingInboxList);
  return updatedInboxs;
};

export const useInbox = (
  collectSystemStatus = (data:SystemStatusResponse) => {},
) => {
  const auth = useAuth();
  const { pigspinApiInstance } = useAxios();
  const [, setBonusState] = useRecoilState(bonusState);
  const [, setInboxTransaction] = useRecoilState(inboxTransactionState);
  const [, setInboxAffiliate] = useRecoilState(inboxAffiliateState);
  const [, setInboxSystemStatus] = useRecoilState(inboxSystemStatusState);
  const [, setIncomingBigWin] = useRecoilState(incomingBigWinState);
  const { callGetBalance } = useBalance();
  const { socket, connectSocket, disconnectSocket } = useSocket();
  const transactionsPerPage = 15;
  const updateSystemInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return;
    setInboxSystemStatus((current) => {
      return {
        ...current,
        inboxs: insertAndUpdateInboxs(current.inboxs, inboxs),
      };
    });
  };

  const updateTransactionInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return;
    setInboxTransaction((current) => {
      return {
        ...current,
        inboxs: insertAndUpdateInboxs(current.inboxs, inboxs),
      };
    });
    callGetBalance();
  };
  const deleteTransactionInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return;
    setInboxTransaction((current) => {
      return {
        ...current,
        inboxs: deleteInboxs(current.inboxs, inboxs),
      };
    });
    callGetBalance();
  };
  const updateAffiliateInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return;
    setInboxAffiliate((current) => {
      return {
        ...current,
        inboxs: insertAndUpdateInboxs(current.inboxs, inboxs),
      };
    });
  };
  const deleteAffiliateInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return;
    setInboxAffiliate((current) => {
      return {
        ...current,
        inboxs: deleteInboxs(current.inboxs, inboxs),
      };
    });
    callGetBalance();
  };

  const updateAllMessage = (allMessage: InboxMessage[]) => {
    const allInbox = allMessage.map(mapToInboxItem);
    const { transaction, affiliate, system } = inboxSelector(allInbox);
    updateTransactionInbox(transaction);
    updateAffiliateInbox(affiliate);
    const systemInboxs = system.filter((item) => item?.productType === undefined || item?.productType === 'PIGSPIN');
    updateSystemInbox(systemInboxs);
  };
  const deleteAllMessage = (allMessage: InboxMessage[]) => {
    const allInbox = allMessage.map(mapToInboxItem);
    const { transaction, affiliate } = inboxSelector(allInbox);
    deleteTransactionInbox(transaction);
    deleteAffiliateInbox(affiliate);
  };

  const {
    isFetching: isFetchingTransaction,
    isFetchingNextPage: isFetchingTransactionNextPage,
    refetch: refetchTransactionPage,
    fetchNextPage: fetchNextTransactionPage,
    hasNextPage: hasNextTransactiontPage = false,
  } = useInfiniteQuery<InboxMessage[]>(['transactions', auth.userUID], async ({ pageParam = 1 }) => {
    const page = pageParam;
    if (!auth.userUID) return Promise.resolve([]);
    const response = await fetchWithJSON<InboxMessage[]>(pigspinApiInstance, {
      url: `v1/inbox/messages/pagination/${auth.userUID}?page=${page}&length=${transactionsPerPage}&inbox_type=${'transaction'}`,
      method: 'GET',
    });
    const dailySpinNotification = response.find((i) => i.message.includes('dailySpin'));
    if (!!dailySpinNotification && !dailySpinNotification.is_read) response.unshift(dailySpinNotification);
    return Promise.resolve((!isEmpty(response) && Array.isArray(response)) ? response : []);
  }, {
    enabled: false,
    getNextPageParam: (lastPage, pages) => {
      if (Array.isArray(lastPage) && lastPage.length > 0) return (pages?.length || 1) + 1;
      return 1;
    },
    onSuccess: ({ pages }) => {
      const mappedMessages = pages.flatMap((obj) => obj);
      updateAllMessage(mappedMessages);
    },
    onError: () => {
      return undefined;
    },
  });
  const {
    isFetching: isFetchingAffiliate,
    isFetchingNextPage: isFetchingNextAffiliatePage,
    refetch: refetchAffiliatePage,
    fetchNextPage: fetchNextAffiliatePage,
    hasNextPage: hasNextAffiliatePage = false,
  } = useInfiniteQuery(['transactions-affiliate', auth.userUID], async ({ pageParam = 1 }) => {
    const page = pageParam;
    if (!auth.userUID) return Promise.resolve([]);
    const response = await fetchWithJSON<InboxMessage[]>(pigspinApiInstance, {
      url: `v1/inbox/messages/pagination/${auth.userUID}?page=${page}&length=${transactionsPerPage}&inbox_type=${'affiliate'}`,
      method: 'GET',
    });
    return Promise.resolve((!isEmpty(response) && Array.isArray(response)) ? response : []);
  }, {
    enabled: false,
    getNextPageParam: (lastPage, pages) => {
      if (Array.isArray(lastPage) && lastPage.length > 0) return (pages?.length || 1) + 1;
      return 1;
    },
    onSuccess: ({ pages }) => {
      const mappedMessages = pages.flatMap((obj) => obj);
      updateAllMessage(mappedMessages);
    },
    onError: () => {
      return undefined;
    },
  });
  const isLoading = useMemo(() => {
    return isFetchingTransaction
      && isFetchingTransactionNextPage
      && isFetchingAffiliate
      && isFetchingNextAffiliatePage;
  }, [isFetchingTransaction, isFetchingTransactionNextPage, isFetchingAffiliate, isFetchingNextAffiliatePage]);

  const { isLoading: isDoingClaimCashback, mutate: claimCashback } = useMutation({
    mutationFn: async (messageId: string) => {
      const response = await fetchWithJSON<InboxMessage[]>(pigspinApiInstance, {
        url: 'v1/inbox/messages',
        method: 'PUT',
        data: {
          uid: messageId,
        },
      });
      return Promise.resolve({});
    },
  });

  const getPrefixTenant = (customerCode: string) => {
    return customerCode.substring(0, 2);
  };
  const connectInboxSocket = () => {
    connectSocket({
      prefixTenant: getPrefixTenant(auth.customerCode),
      token: auth.cfid?.replace('bearer', '').trim(),
      onConnected(_socket) {
        if (!isLoading) {
          fetchNextTransactionPage({ pageParam: 1, cancelRefetch: true });
          fetchNextAffiliatePage({ pageParam: 1, cancelRefetch: true });
        }
        _socket.on('toast', (data: string[]) => {
          data.forEach((message) => {
            toast(parse(message));
          });
        });
        _socket.on('system_status_pigspin', (data: any[]) => {
          data.forEach((message:SystemStatusResponse) => {
            collectSystemStatus(message);
          });
        });
        _socket.on('push_inbox', (data: InboxMessage[]) => {
          // NOTE : read inbox
          updateAllMessage(data);
        });
        _socket.on('delete_inbox', (data: InboxMessage[]) => {
          // NOTE : read inbox
          deleteAllMessage(data);
        });
        _socket.on('update_coin_wallet', (data: BonusEvent[]) => {
          const bonusData = data[0] || {
            activeBonus: [],
            completedBonus: [],
          };
          setBonusState({
            activeBonus: bonusData.activeBonus,
            completedBonus: bonusData.completedBonus,
          });
        });
        _socket.on('error', () => {
          toast('ระบบ notificatoin มีปัญหา', { autoClose: 2000 });
        });
        _socket.on('upcoming_bigwin', (data: BigWinChampionDetail) => {
          setIncomingBigWin(data);
        });
      },
    });
  };

  const disconnectInboxSocket = () => {
    if (socket && socket.connected) {
      disconnectSocket();
      setInboxTransaction(defaultInboxState);
      setInboxAffiliate(defaultInboxState);
      setInboxSystemStatus(defaultInboxState);
    }
  };

  const readInbox = (inboxs: InboxItem[]) => {
    const read = (inbox: InboxItem): InboxMessage => ({
      ...mapToInboxMessage(inbox),
      is_read: true,
      product_type: 'PIGSPIN',
    });
    if (socket) {
      socket.emit('read_inbox', {
        auth: auth.cfid?.replace('bearer', '').trim(),
        data: inboxs.map(read),
      });
    }
  };

  const loadMore = (type:string) => {
    if (!isLoading) {
      switch (type) {
        case 'transaction':
          fetchNextTransactionPage();
          break;
        case 'affiliate':
          fetchNextAffiliatePage();
          break;
        default:
          break;
      }
    }
  };

  return {
    isDoingClaimCashback,
    isLoading,
    hasNextTransactiontPage,
    hasNextAffiliatePage,
    connectInboxSocket,
    claimCashback,
    disconnectInboxSocket,
    readInbox,
    refetchTransactionPage,
    refetchAffiliatePage,
    loadMore,
  };
};
