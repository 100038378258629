import React from 'react';
import { useResetRecoilState } from 'recoil';
import isBefore from 'date-fns/isBefore';
import { useMutation } from 'react-query';
import clearAllState from '@/recoils/reset';
import { COOKIES_KEY, useCookies } from '@/utils/cookies';
import { toJwtData } from '@/utils/jwt';
import { revokeToken } from '@/services/token/revoke';

type AuthrozieInfo = {
  cfid: string;
  uid: string;
  customerCode: string;
};

export type AuthState = {
  cfid: string;
  customerCode: string;
  gameToken: string,
  userUID: string;
  isAuthorize: boolean;
  authorize: (params: AuthrozieInfo) => void;
  clear: () => void;
};

export const useAuth = (): AuthState => {
  const [cookies, setCookies, removeCookies] = useCookies<string>([COOKIES_KEY.CFID, COOKIES_KEY.CUSTOMER_CODE, COOKIES_KEY.UID]);
  const clearState = useResetRecoilState(clearAllState);
  const revokeTokenMutate = useMutation({
    mutationFn: revokeToken,
  });

  const clearCookieFlag = () => {
    removeCookies(COOKIES_KEY.DAILY_LOGIN);
    removeCookies(COOKIES_KEY.DAILY_SPIN);
    removeCookies(COOKIES_KEY.LOBBY_ANNOUCEMENT);
  };

  const authorize = React.useCallback((params: AuthrozieInfo) => {
    const tokenData = toJwtData(params?.cfid || '');
    const expireAt = new Date(tokenData.exp);
    setCookies(COOKIES_KEY.CFID, params.cfid, { expires: expireAt });
    setCookies(COOKIES_KEY.UID, params.uid, { expires: expireAt });
    setCookies(COOKIES_KEY.CUSTOMER_CODE, params.customerCode, { expires: expireAt });

    // NOTE : clear flag
    clearCookieFlag();
  }, []);
  const clearAuth = React.useCallback(() => {
    revokeTokenMutate.mutate(cookies.cfid || '');
    clearCookieFlag();
    removeCookies(COOKIES_KEY.CFID);
    removeCookies(COOKIES_KEY.UID);
    removeCookies(COOKIES_KEY.CUSTOMER_CODE);
    clearState();
  }, [cookies.cfid]);
  const auth = React.useMemo(() => {
    const token = cookies.cfid || '';
    const tokenData = toJwtData(token);
    const expireAt = new Date(tokenData.exp);
    const isTokenExpire = isBefore(expireAt, new Date());
    return {
      authorize,
      clear: clearAuth,
      isAuthorize: (token.startsWith('bearer') && !isTokenExpire) || false,
      cfid: token,
      customerCode: cookies.customer_code || '',
      userUID: tokenData.user_uid || '',
      gameToken: tokenData.game_token || '',
    };
  }, [cookies.cfid, cookies.customer_code, cookies.uid]);
  return auth;
};
