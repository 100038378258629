/* eslint-disable prefer-template */

import { LINE_CHANNEL_ID } from '@/models/buildtime-constant';

export enum SCOPE_ENUM {
  PROFILE = 'profile',
  OPENID = 'openid',
  EMAIL = 'email',
}

const getLineWebLoginUrl = (redirectUri: string, state: string, nonce: string, scopes: SCOPE_ENUM[] = []) => {
  const scope = scopes.join('%20');
  const encodedCallbackUrl = encodeURI(redirectUri);
  const channelId = LINE_CHANNEL_ID;
  return 'https://access.line.me/oauth2/v2.1/authorize?response_type=code'
    + '&client_id=' + channelId
    + '&redirect_uri=' + encodedCallbackUrl
    + '&state=' + state
    + '&scope=' + scope
    + '&nonce=' + nonce;
};

export default getLineWebLoginUrl;
