import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { GameItem } from '@/services/games';

import { KEYS } from '../keys';

const { persistAtom } = recoilPersist();

export type GameState = {
  data: GameItem[];
};

export type GameMetaState = {
  isLoading: boolean;
  isSuccess: boolean;
};

export const initialValue: GameItem[] = [];

const gameState = atom<GameItem[]>({
  key: KEYS.GAME,
  default: initialValue,
  effects_UNSTABLE: [persistAtom],
});

export const initialMetaValue: GameMetaState = {
  isLoading: false,
  isSuccess: false,
};

export const gameMetaState = atom<GameMetaState>({
  key: KEYS.GAME_META,
  default: initialMetaValue,
});

export default gameState;
