export const addCommas = (number: string): string => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeCommas = (number: string): string => {
  return number.replace(/,/g, '');
};

export const numberWithCommas = (number: number, decimal?: number): string => {
  const numStr = (!decimal && decimal !== 0) ? `${number}` : number.toFixed(decimal);
  const [numVal, decimalVal] = numStr.split('.');
  const result = `${addCommas(numVal)}${decimalVal ? `.${decimalVal}` : ''}`;
  return result;
};

export const numberWithoutCommas = (number: number | string): number => {
  const result = parseFloat(removeCommas(number.toString()));
  return result || 0;
};

export const validateNumber = (val: any) => (!Number.isNaN(+val));

export type RandomParams = {
  movingNumber: number;
  initialNumber: number;
  swingHighPercent?: number;
  swingLowPercent?: number;
};

export const randomNumber = (params: RandomParams): number => {
  const {
    initialNumber,
    movingNumber,
    swingHighPercent = 0.01, swingLowPercent = 0.01, // NOTE : 1 percent length to high and low
  } = params;
  const swingHigh = initialNumber + (initialNumber * swingHighPercent);
  const swingLow = initialNumber - (initialNumber * swingLowPercent);
  const swingLength = swingHigh - swingLow;
  let randomMove = Math.floor((Math.random() * swingLength)) - (initialNumber - swingLow);
  if (movingNumber >= swingHigh) { randomMove = Math.abs(randomMove) * -1; }
  if (movingNumber <= swingLow) { randomMove = Math.abs(randomMove); }
  return randomMove;
};
