import React, { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

import { SOCKET_ENDPOINT } from '@/models/buildtime-constant';

export type ConnectSocketParams = {
  token?: string;
  prefixTenant?: string;
  onConnected?: (socket: Socket) => void;
};

export type SocketContextType = {
  socket: Socket | null;
  connectSocket: (params: ConnectSocketParams) => void;
  disconnectSocket: () => void;
};

const SocketContext = React.createContext<SocketContextType>({
  socket: null,
  connectSocket: () => { },
  disconnectSocket: () => { },
});

export const useSocket = () => {
  return React.useContext(SocketContext);
};

export const SocketProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    return () => {
      // Clean up socket on unmount
      socket?.disconnect();
    };
  }, []);

  const connectSocket = (params: ConnectSocketParams) => {
    const newSocket = io(SOCKET_ENDPOINT, {
      reconnectionAttempts: 0,
      query: {
        origin: params.prefixTenant,
      },
      auth: {
        token: params.token,
      },
    });
    setSocket(newSocket);
    if (params.onConnected && newSocket) { params.onConnected(newSocket); }
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
    }
  };

  return (
    <SocketContext.Provider value={{ socket, connectSocket, disconnectSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
