import { atom, selectorFamily } from 'recoil';

import { UncapitalizeObjectKeys } from '@/utils/common';
import { SystemStatusResponse, SystemStatusToastFlag } from '@/hooks/useSystemStatus/type';

import { KEYS } from '../keys';

export enum ERROR_STATUS_VALUE {
  FRIEND_INVITE = -4,
  CLOSE_REGISTER = -3,
  INACTIVE_USER = -2,
  UNDER_CONSTRUCTION = -1,
  IGNORE = 0,
  NONE = 1,
  DEPOSIT = 2, // depositError
  WITHDRAW = 3, // withdrawError
  GAME = 5, // gameError
  DEPOSIT_AND_WITHDRAW = DEPOSIT * WITHDRAW,
  DEPOSIT_AND_GAME = DEPOSIT * GAME,
  WITHDRAW_AND_GAME = WITHDRAW * GAME,
  ALL = DEPOSIT * WITHDRAW * GAME,
  SCB_DEPOSIT = 7, // SCBError
  SCB_DEPOSIT_AND_WITHDRAW = SCB_DEPOSIT * WITHDRAW,
  SCB_DEPOSIT_AND_GAME = SCB_DEPOSIT * GAME,
  SCB_DEPOSIT_AND_WITHDRAW_AND_GAME = SCB_DEPOSIT * WITHDRAW * GAME,
}

export type ExcludeSystemStatusFlag = 'SCBError' | 'Toast' | 'GameProviderPG' | 'GameProviderPNG' | 'GameProviderEVO' | 'GameProviderJOKER';

export type SystemStatusType = UncapitalizeObjectKeys<Omit<SystemStatusResponse, ExcludeSystemStatusFlag>
> & {
  SCBError: boolean;
  isSCBUser: boolean;
  lastUpdate: Date;
  toast: UncapitalizeObjectKeys<SystemStatusToastFlag>;
} & {
  // TODO : deprecated. remove this flag
  gameProvider: {
    PG: boolean;
    PNG: boolean;
    EVO: boolean;
    JOKER: boolean;
    JILI: boolean;
  }
};

export const systemStatusInitialValue: SystemStatusType = {
  depositError: false,
  withdrawError: false,
  gameError: false,
  SCBError: false,
  friendInvite: false,
  register: false,
  kioskError: false,
  underConstruction: false,
  underConstructionText: '',
  freeStyle: false,
  freeStyleText: '',
  freeStyleSCB: false,
  freeStyleTextSCB: '',
  valentine: false,
  isSCBUser: false,
  lastUpdate: new Date(),
  inbox: [],
  toast: {
    depositError: false,
    friendInvite: false,
    gameError: false,
    register: false,
    withdrawError: false,
  },
  gameProvider: {
    PG: false,
    PNG: false,
    EVO: false,
    JOKER: false,
    JILI: false,
  },
};

export const systemStatusState = atom<SystemStatusType>({
  key: KEYS.SYSTEM_STATUS,
  default: systemStatusInitialValue,
});

export const systemStatusToastInitialValue = {
  currentMessage: '',
};

export const systemStatusToastState = atom({
  key: KEYS.SYSTEM_STATUS_TOAST,
  default: systemStatusToastInitialValue,
});

export const calculatedSystemStatusState = selectorFamily<ERROR_STATUS_VALUE, { isAuthen: boolean }
>({
  key: 'currentSystemStatus',
  get:
    ({ isAuthen }) => ({ get }) => {
      const systemStatus = get(systemStatusState);
      const {
        SCBError,
        isSCBUser,
        underConstruction,
        friendInvite,
      } = systemStatus;
      const {
        gameError,
        withdrawError,
        depositError,
        register,
      } = systemStatus.toast;

      if (underConstruction) return ERROR_STATUS_VALUE.UNDER_CONSTRUCTION;
      if (register === true && !isAuthen) {
        return ERROR_STATUS_VALUE.CLOSE_REGISTER;
      }

      const gameErrorValue = gameError
        ? ERROR_STATUS_VALUE.GAME
        : ERROR_STATUS_VALUE.NONE;
      let scbErrorValue = isSCBUser && SCBError
        ? ERROR_STATUS_VALUE.SCB_DEPOSIT
        : ERROR_STATUS_VALUE.NONE;
      const withdrawErrorValue = withdrawError
        ? ERROR_STATUS_VALUE.WITHDRAW
        : ERROR_STATUS_VALUE.NONE;
      let depositErrorValue = depositError
        ? ERROR_STATUS_VALUE.DEPOSIT
        : ERROR_STATUS_VALUE.NONE;

      // NOTE : SCB user Special case
      if (isSCBUser && SCBError && depositError) {
        depositErrorValue = ERROR_STATUS_VALUE.DEPOSIT;
        scbErrorValue = ERROR_STATUS_VALUE.NONE;
      }

      const totalValue = gameErrorValue * scbErrorValue * withdrawErrorValue * depositErrorValue;
      if (!(totalValue > 1) && friendInvite === true) {
        return ERROR_STATUS_VALUE.FRIEND_INVITE;
      }
      return totalValue as ERROR_STATUS_VALUE;
    },
});

export default systemStatusState;
