import { atom } from 'recoil';

import { KEYS } from '../keys';

export const initialValue = false;

const tutorialModalState = atom<boolean>({
  key: KEYS.TUTORIAL_MODAL,
  default: initialValue,
});

export default tutorialModalState;
