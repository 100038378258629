import { useMediaQuery } from 'react-responsive';
import { breakPoints } from '@/utils/responsive';

export type ResponsesiveState = {
  isXS: boolean;
  isSM: boolean;
  isMD: boolean;
  isLG: boolean;
  isXL: boolean;
};

export const useResponsiveState = (): ResponsesiveState => {
  const isXS = useMediaQuery({ minWidth: breakPoints.xs });
  const isSM = useMediaQuery({ minWidth: breakPoints.sm });
  const isMD = useMediaQuery({ minWidth: breakPoints.md });
  const isLG = useMediaQuery({ minWidth: breakPoints.lg });
  const isXL = useMediaQuery({ minWidth: breakPoints.xl });
  return {
    isXS,
    isSM,
    isMD,
    isLG,
    isXL,
  };
};
