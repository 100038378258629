import { atom } from 'recoil';

import { KEYS } from '../keys';

export type GameItem = {
  campaign_code: string;
  game_code_list: string[];
};

export type GamesState = {
  data: GameItem;
};

export const initialValue: Partial<GamesState> | undefined = {
  data: undefined,
};

const RankingGameState = atom<Partial<GamesState | undefined>>({
  key: KEYS.RANK_GAME,
  default: initialValue,
});

export default RankingGameState;
