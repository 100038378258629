import { AxiosRequestConfig } from 'axios';
import { useRecoilState } from 'recoil';

import { BaseApiResponse } from 'services/service.type';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetchWithJSON } from '@/utils/api/fetch';

import { useAuth } from './useAuth';
import { favoriteGameState } from '@/recoils/index';
import { useAxios } from './useAxios';
import { GameItem } from '@/services/games';
import { useRouter } from 'next/router';
import callFavoriteGame from '@/services/favorite';
import { FavoriteGameItem, UpdateFavoriteGameResponse } from '@/services/favorite/favorite.type';
import isEmpty from 'lodash/isEmpty';

export type FavoriteGameHook = {
  favoriteGameData: GameItem[];
  isFetchingFavoriteGame: boolean;
  fetchFavorite: () => void;
  callUpdateFavoriteGame: (
    request: FavoriteGameItem
  ) => Promise<UpdateFavoriteGameResponse>;
  checkFavorite: (
    request: GameItem,
  ) => boolean;
};

export const useFavoriteGame = (getQueryOptions?: UseQueryOptions): FavoriteGameHook => {
  const { pigspinApiInstance } = useAxios();
  const auth = useAuth();
  const router = useRouter();
  const [favoriteState, setFavoriteState] = useRecoilState(favoriteGameState);

  const {
    data: favoriteGameData = [],
    refetch: fetchFavorite,
    isFetching: isFetchingFavoriteGame,
  } = useQuery<GameItem[], any>(['favoriteGame'], async () => {
    return (isEmpty(auth.customerCode)) ? [] : callFavoriteGame(auth.customerCode);
  }, {
    enabled: false,
    onSuccess: (result: GameItem[]) => {
      setFavoriteState(result);
    },
  });
  const callUpdateFavoriteGame = async (
    request: FavoriteGameItem,
  ): Promise<UpdateFavoriteGameResponse> => {
    const payload: FavoriteGameItem = {
      ...request,
      customer_code: auth.customerCode || request.customer_code,
    };
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/update_favorite_game',
      method: 'PUT',
    };
    const result = await fetchWithJSON<UpdateFavoriteGameResponse>(
      pigspinApiInstance,
      requestConfig,
      payload,
    );
    fetchFavorite();
    return result;
  };

  const checkFavorite = (
    request: GameItem,
  ): boolean => {
    const { game_code: gameCode, game_id: gameId } = request;
    const isFavorited = Array.isArray(favoriteGameData) && favoriteGameData.some(({ game_code, game_id }) => game_code === gameCode && game_id === gameId);
    return isFavorited || false;
  };

  return {
    favoriteGameData: favoriteState,
    isFetchingFavoriteGame,
    fetchFavorite,
    callUpdateFavoriteGame,
    checkFavorite,
  };
};
