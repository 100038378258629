import { atom } from 'recoil';

import { KEYS } from '../keys';
import { GuildInfo } from '@/hooks/useGuildSystem';

export interface IGuildState {
  data: GuildInfo;
}

export const initialValue: IGuildState | undefined = undefined;

const guildInfoState = atom<IGuildState | undefined>({
  key: KEYS.GUILD_INFO,
  default: initialValue,
});

export default guildInfoState;
