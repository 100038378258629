import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import {
  Button, ButtonProps, Spin,
} from 'antd';
import styled, { useTheme } from 'styled-components';
import { COOKIES_KEY, useCookies } from '@/utils/cookies';
import { useFavoriteGame } from '@/hooks/useFavoriteGame';

const StyledFavoriteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  z-index: 12;
`;

export type FavoriteButtonProps = ButtonProps & {
  isFavorited: boolean;
  gameCode: string;
  gameId: string;
  refType: string;
  overriedIconSize?: string | number;
  autoFavoriteRequest?: boolean;
  autoRedirect?: boolean;
  redirectPath?: string;
  customerCode?: string;
  isOnDarkSurface?: boolean;
  onClick?: (e:any) => void;
};

export const FavoriteButton = React.forwardRef<
HTMLButtonElement,
FavoriteButtonProps
>(
  (
    {
      id,
      onClick,
      disabled,
      className,
      overriedIconSize = 20,
      isFavorited: isFavoritedProps = false,
      gameCode,
      gameId,
      refType,
      customerCode,
      autoFavoriteRequest = true,
      autoRedirect = true,
      redirectPath = '/login',
      isOnDarkSurface = false,
    },
    buttonRef,
  ) => {
    const router = useRouter();
    const [cookies, ,] = useCookies<string>([
      COOKIES_KEY.CFID,
      COOKIES_KEY.UID,
    ]);
    const { callUpdateFavoriteGame } = useFavoriteGame({ enabled: false });
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isFavorited, setFavorited] = useState<boolean>(isFavoritedProps);
    const theme:any = useTheme();

    const favoriteStyle = {
      fontSize: overriedIconSize,
    };

    const handleOnClicked = async (e: any) => {
      const isAuth = !!(cookies.uid || cookies.cfid);
      if (autoRedirect && !isAuth) {
        router.push(redirectPath);
        return;
      }
      setLoading(true);
      if (autoFavoriteRequest) {
        const resp = await callUpdateFavoriteGame({
          customer_code: customerCode,
          game_id: gameId,
          game_code: gameCode,
          ref_type: refType,
          is_favorite: !isFavorited,
        });
        setFavorited(resp.data.is_favorite);
        setLoading(false);
      }
      if (onClick) onClick(e);
    };

    useEffect(() => {
      setFavorited(isFavoritedProps);
    }, [isFavoritedProps]);

    const getIconColorClassNames = () => ({
      'ps-favorite-button--on-dark-bg': isOnDarkSurface,
      'ps-favorite-button--on-light-bg ps-favorite-button--on-light-bg--active':
        !isOnDarkSurface && isFavorited,
      'ps-favorite-button--on-light-bg': !isOnDarkSurface && !isFavorited,
    });
    const iconColorClassNames = useMemo(getIconColorClassNames, [
      isOnDarkSurface,
      isFavorited,
    ]);
    const favIconStyle = useMemo(() => {
      return {
        ...favoriteStyle,
        color: `${isFavorited ? theme['orange-300'] : theme['greyscale-300']}`,
        textShadow: `${isOnDarkSurface ? '0 0 2px rgba(0,0,0,0.2)' : 'none'}`,
      };
    }, [isFavorited]);

    return (
      <StyledFavoriteButton
        id={id}
        {...(!isLoading ? { onClick: handleOnClicked } : {})}
        disabled={disabled}
        className={classNames(
          { active: isFavorited },
          iconColorClassNames,
        )}
        ref={buttonRef}
        type="link"
      >
        {isLoading ? (
          <Spin style={{ color: theme['primary-400'] }} />
        ) : (
          <i
            className={isFavorited || isOnDarkSurface ? 'ps-icon-star-filled' : 'ps-icon-star'}
            style={favIconStyle}
          />
        )}
      </StyledFavoriteButton>
    );
  },
);

FavoriteButton.displayName = 'FavoriteButton';

export default FavoriteButton;
