import React from 'react';

export type BaseButtonProps = {
  id?: string;
  prependContent?: React.ReactNode;
  children?: React.ReactNode;
  appendContent?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  nativeType?: 'button' | 'submit' | 'reset';
  wrapperStyle?: React.CSSProperties;
};

export enum BUTTON_TYPE {
  NONE = 'none-cta',
  CTA_1 = 'cta-1',
  CTA_2 = 'cta-2',
  CTA_3 = 'cta-3',
  CTA_4 = 'cta-4',
  OUTLINE = 'outline',
  OUTLINE_BLACK = 'outline-black',
  WHITE = 'white',
  OUTLINE_WHITE = 'outline-white',
  OUTLINE_GREY = 'outline-grey',
  OUTLINE_CTA_1 = 'outline-cta-1',
  OUTLINE_CTA_4 = 'outline-cta-4',
  OUTLINE_CTA_1_DISABLED = 'outline-cta-1-disabled',
  PLAIN_TEXT = 'plain-text',
}

export enum BUTTON_SIZE {
  SM = 'small',
  MD = 'medium',
  LG = 'large',
}
