import { useRecoilValue, useRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'next-i18next';
import isEmpty from 'lodash/isEmpty';
import {
  SystemStatusType, ERROR_STATUS_VALUE, systemStatusState, systemStatusToastState,
} from '@/recoils/modules/system-status';
import { useCookies, COOKIES_KEY } from '@/utils/cookies';
import { toCamelCase } from '@/utils/utils';
import profileState from '@/recoils/modules/profile';
import { fetchWithJSON } from '@/utils/api/fetch';
import { BaseBoResponse } from '@/services/service.type';
import { useAxios } from '../useAxios';
import { mapToInboxItem } from '@/mappers/mapToInboxItem';
import { inboxSelector, insertAndUpdateInboxs, useInbox } from '../useInbox';
import { inboxSystemStatusState } from '@/recoils/modules/inbox';
import { SystemStatusFlag, SystemStatusResponse } from './type';
import mapToastErrorState from '@/mappers/mapToastErrorState';

const useSystemStatus = () => {
  const { t } = useTranslation(['common', 'system-status', 'guild']);
  const { pathname } = useRouter();
  const { boApiInstance } = useAxios();
  const [cookies] = useCookies<string>([COOKIES_KEY.UID, COOKIES_KEY.CFID]);
  const isAuthen = !!(cookies.uid && cookies.cfid);

  const [recoilSystemStatus, setRecoilSystemStatus] = useRecoilState(systemStatusState);
  const [, setSystemStatusToast] = useRecoilState(systemStatusToastState);
  const [, setInboxSystemStatus] = useRecoilState(inboxSystemStatusState);
  const profile = useRecoilValue(profileState);

  const generateSystemToast = (prop:SystemStatusType) => {
    const {
      isSCBUser,
      freeStyle,
      freeStyleText,
      freeStyleSCB,
      freeStyleTextSCB,
    } = prop;

    const errorState = mapToastErrorState({
      systemStatus: prop,
      isAuthen,
    });

    let message = '';
    if (isSCBUser && freeStyleSCB && freeStyleTextSCB?.trim()) {
      // SCB user announcements
      message = freeStyleTextSCB?.trim();
    } else
    if (freeStyle && freeStyleText?.trim()) {
      // global announcements
      message = (freeStyle && freeStyleText?.trim()) ? freeStyleText?.trim() : '';
    } else
    if (isAuthen) {
      // registered user
      switch (errorState) {
        case ERROR_STATUS_VALUE.NONE:
          message = '';
          break;
        case ERROR_STATUS_VALUE.DEPOSIT:
          message = 'toast.forNormalUser.depositeError';
          break;
        case ERROR_STATUS_VALUE.WITHDRAW:
          message = 'toast.forNormalUser.withdrawError';
          break;
        case ERROR_STATUS_VALUE.GAME:
          message = 'toast.forNormalUser.gameError';
          break;
        case ERROR_STATUS_VALUE.FRIEND_INVITE:
          message = 'toast.forNormalUser.friendInvite';
          break;
        case ERROR_STATUS_VALUE.DEPOSIT_AND_WITHDRAW:
          message = 'toast.forNormalUser.depositeAndWithdrawError';
          break;
        case ERROR_STATUS_VALUE.DEPOSIT_AND_GAME:
          message = 'toast.forNormalUser.depositeAndGameError';
          break;
        case ERROR_STATUS_VALUE.WITHDRAW_AND_GAME:
          message = 'toast.forNormalUser.withdrawAndGameError';
          break;
        case ERROR_STATUS_VALUE.ALL:
          message = 'toast.forNormalUser.depositeAndWithdrawAndGameError';
          break;
        case ERROR_STATUS_VALUE.SCB_DEPOSIT:
          message = 'toast.forSCBUser.depositeError';
          break;
        case ERROR_STATUS_VALUE.SCB_DEPOSIT_AND_WITHDRAW:
          message = 'toast.forSCBUser.depositeAndWithdrawError';
          break;
        case ERROR_STATUS_VALUE.SCB_DEPOSIT_AND_GAME:
          message = 'toast.forSCBUser.depositeAndGameError';
          break;
        case ERROR_STATUS_VALUE.SCB_DEPOSIT_AND_WITHDRAW_AND_GAME:
          message = 'toast.forSCBUser.depositeAndWithdrawAndGameError';
          break;
        default:
          message = '';
      }
    } else if (['/', '/home', '/register'].includes(pathname)) {
      // guest user
      switch (errorState) {
        case ERROR_STATUS_VALUE.CLOSE_REGISTER:
          message = 'toast.closeRegister';
          break;
        default:
          message = '';
      }
    }
    const isThereLink = message.indexOf('<a') !== -1;

    if (!isEmpty(message)) {
      setSystemStatusToast({
        currentMessage: isThereLink ? message : t(`system-status:${message}`),
      });
    }
    return message;
  };

  const collectSystemStatus = (data:any) => {
    const systemStatusFlag: SystemStatusFlag = {
      DepositError: false,
      FreeStyle: false,
      FreeStyleSCB: false,
      FreeStyleText: '',
      FreeStyleTextSCB: '',
      FriendInvite: false,
      GameError: false,
      KioskError: false,
      Register: false,
      SCBError: false,
      UnderConstruction: false,
      UnderConstructionText: '',
      Valentine: false,
      WithdrawError: false,
      GameProviderPG: false,
      GameProviderPNG: false,
      GameProviderEVO: false,
      GameProviderJOKER: false,
    };
    const systemStatusResponse: SystemStatusResponse = {
      ...systemStatusFlag,
      Inbox: [],
      Toast: { ...systemStatusFlag },
      ...data,
    };

    const {
      SCBError = false,
      Inbox,
      Toast,
      GameProviderPG,
      GameProviderPNG,
      GameProviderEVO,
      GameProviderJOKER,
      ...excludeSCBErrorStatus
    } = systemStatusResponse;
    const isSCBUser = profile?.data.bank_account_list[0].bank_code === 'SCB';
    const allInbox = Inbox.map(mapToInboxItem).slice().reverse();
    const { system } = inboxSelector(allInbox);
    if (system.length > 0) {
      setInboxSystemStatus((current) => {
        return {
          ...current,
          inboxs: insertAndUpdateInboxs(current.inboxs, system),
        };
      });
    }
    const updatedSystemStatus = {
      ...toCamelCase(excludeSCBErrorStatus),
      toast: toCamelCase(systemStatusResponse.Toast),
      SCBError,
      isSCBUser,
      lastUpdate: new Date(),
      gameProvider: {
        PG: GameProviderPG,
        PNG: GameProviderPNG,
        EVO: GameProviderEVO,
        JOKER: GameProviderJOKER,
      },
    } as SystemStatusType;
    setRecoilSystemStatus(updatedSystemStatus);
    generateSystemToast(updatedSystemStatus);
    return updatedSystemStatus;
  };
  const { connectInboxSocket } = useInbox(collectSystemStatus);
  const {
    data: systemStatusData,
    refetch: fetchSystemStatus,
    isFetching: isLoading,
  } = useQuery<SystemStatusType>(['SystemStatus'], async () => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/feature-toggle/system-status',
      method: 'GET',
    };
    const response = await fetchWithJSON<BaseBoResponse<SystemStatusResponse>>(boApiInstance, requestConfig);
    const result = collectSystemStatus(response.data);
    return result;
  }, {
    enabled: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    onSuccess: setRecoilSystemStatus,
  });

  const refreshSystemStatus = async () => {
    try {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/feature-toggle/system-status',
        method: 'GET',
      };
      const response = await fetchWithJSON<BaseBoResponse<SystemStatusResponse>>(boApiInstance, requestConfig);
      const result = collectSystemStatus(response.data);
      return result;
    } catch (error) {
      return null;
    }
  };
  // const { connectInboxSocket } = useInbox(collectSystemStatus);

  return {
    systemStatus: recoilSystemStatus,
    connectInboxSocket,
    fetchSystemStatus,
    refreshSystemStatus,
    collectSystemStatus,
  };
};

export default useSystemStatus;
