import dayjs from 'dayjs';
import { InboxItem } from '@/recoils/modules/inbox';
import { forceDateToISO } from '@/utils/datetime';
import { InboxMessage } from './type';

export const mapToInboxItem = (message: InboxMessage): InboxItem => {
  return {
    uid: message.uid,
    userUid: message.user_uid,
    title: message.subject || '',
    subTitle: message.subtitle,
    content: message.message || '',
    type: message.inbox_type,
    icon: message.icon,
    date: dayjs(message.date_time).toDate(),
    isRead: message.is_read,
    start: message.start ? forceDateToISO(message.start) : undefined,
    end: message.end ? forceDateToISO(message.end) : undefined,
    createdAt: dayjs(message.created_at).toDate(),
    updatedAt: dayjs(message.updated_at).toDate(),
    productType: message?.product_type,
  };
};

export const mapToInboxMessage = (inbox: InboxItem): InboxMessage => {
  return {
    uid: inbox.uid,
    user_uid: inbox.userUid,
    subject: inbox.title,
    subtitle: inbox.subTitle,
    message: inbox.content || '',
    inbox_type: inbox.type,
    icon: inbox.icon || '',
    date_time: inbox.date,
    is_read: inbox.isRead,
    start: inbox.start ? dayjs(inbox.start).toISOString() : undefined,
    end: inbox.end ? dayjs(inbox.end).toISOString() : undefined,
    created_at: inbox.createdAt,
    updated_at: inbox.updatedAt,
  };
};
