import { useErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Button, BUTTON_TYPE } from '../Button';
import NoResult from '../NoResult';

const Style = {
  ErrorScreen: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme['primary-50']};
  `,
  ErrorTitle: styled.h2`
    font-size: 24px;
    opacity: 0.5;
    `,
  Buttons: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  RefreshButton: styled(Button)`
    margin: 12px 0px 0px;
  `,
  ExtraButton: styled(Button)`
    font-size: 20px;
  `,
};
const ErrorPage = () => {
  const { resetBoundary } = useErrorBoundary();
  const router = useRouter();

  return (
    <Style.ErrorScreen>
      <NoResult message="" />
      <Style.ErrorTitle>มีบางอย่างผิดผลาด</Style.ErrorTitle>
      <Style.Buttons>
        <Style.RefreshButton onClick={resetBoundary} type={BUTTON_TYPE.CTA_1}>ลองใหม่อีกครั้ง</Style.RefreshButton>
        <Style.ExtraButton onClick={() => router.push('/logout')} type={BUTTON_TYPE.PLAIN_TEXT}>ออกจากระบบ</Style.ExtraButton>
      </Style.Buttons>
    </Style.ErrorScreen>
  );
};

export default ErrorPage;
