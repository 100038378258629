export type BenefitItem = {
  level: string,
  tier: VIPTiers,
  benefit: string,
};
export type VipRewardItem = {
  uid: string,
  level: string,
  tier: VIPTiers,
  turnOver: number,
  star: number,
  status: 'Received' | 'Disabled' | 'Available',
};
export type VipRewardList = {
  tier: VIPTiers,
  rewards: VipRewardItem[];
};
export enum VIPTiers {
  BRONZE = 'bronze',
  SILVER = 'silver',
  GOLD = 'gold',
  DIAMOND = 'diamond',
}
export type VipProfileData = {
  star: number,
  progress?: number,
  vipTier: VIPTiers,
  vipLevel: string,
  nextLevel?: {
    vipLevel: string,
    turnover: number,
  }
};
export type VipItem = {
  vipLevel: string,
  vipTier: VIPTiers,
  vipStar: number,
  customerCode?: string,
};

export type VipMasterData = {
  tier_icon: string,
  tier: string,
  levels: {
    vip_master_uid: string,
    level: string,
    acc_turnover: number,
    star: number,
    bonus_button: string,
  }
};

export const emptyVip:VipProfileData = {
  star: 0,
  progress: 0,
  vipTier: VIPTiers.BRONZE,
  vipLevel: 'VIP0',
};

export type VipMaster = {
  tier: string,
  icon: string,
  acc_turnover: number,
  tier_icon: string,
  level: number,
};

export type VipReward = {
  detail: string,
};
