import { atom } from 'recoil';

import { KEYS } from '../keys';
import { ProfileData } from '../../services/profile';

export interface IProfileState {
  data: ProfileData;
}

export const initialValue: IProfileState | undefined = undefined;

const profileState = atom<IProfileState | undefined>({
  key: KEYS.PROFILE,
  default: initialValue,
});

export default profileState;
