import React, { useMemo } from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonType } from 'antd/lib/button';
import styled from 'styled-components';
import classNames from 'classnames';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { BaseButtonProps, BUTTON_SIZE, BUTTON_TYPE } from '../Button.type';
import { useResponsiveState } from '@/hooks/useResponsive';

export const HOLDING_CLICK_DURATION = 300;
enum ANTD_BUTTON_TYPE {
  DASH = 'dashed',
  LINK = 'link',
  PRIMARY = 'primary',
  DEFAULT = 'default',
}

export type ButtonProps = BaseButtonProps & {
  id?: string;
  testId?: string;
  type?: BUTTON_TYPE;
  size?: BUTTON_SIZE;
  className?: string;
  overrideWidth?: string | number; // ex. 20, '20px'
  overrideHeight?: string | number; // ex. 20, '20px'
  overridePadding?: string | number; // ex. 0, '20px 16px 10px 16px'
  requiedSpanWrapBody?: boolean;
  buttonForm?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode | string | undefined;
  bouncingInteraction?: boolean;
};

const StyledAntdButton = styled(AntdButton)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400 ;
  line-height: 46px;
  height: 46px;
  padding: 0 24px;
  text-align: center;
  border: 2px dashed red;
  &:disabled {
    opacity: 0.2;
    filter: grayscale(0.2);
  }
  &:hover {
  }
  transition: 0.2s cubic-bezier(0.17, 1, 0.49, 1.82);
  &.bouncing-interaction:active {
    transform: scale(0.95);
  }
  &.ant-btn-sm {
    font-size: 20px;
    font-weight: 400 ;
    line-height: 20px;
    line-height: 32px;
    height: 32px;
  }
  &.ant-btn-lg {
    font-size: 24px;
    font-weight: 600;
    line-height: 60px;
    height: 60px;
  }
  &.plain-text {
    background: transparent;
    border: none;
  }
  &.white {
    background: white;
    border: none;
  }
  &.white-cta-1 {
    background: ${({ theme }) => theme['greyscale-100']};
    border: none;
    color: ${({ theme }) => theme['primary-1']};
  }
  &.cta-1 {
    background: ${({ theme }) => theme['primary-1']};
    border: none;
    color: ${({ theme }) => theme['greyscale-100']};
  }
  &.cta-2 {
    background: ${({ theme }) => theme['greyscale-100']};
    border: none;
    color: ${({ theme }) => theme['primary-1']};
  }
  &.cta-3 {
    background-color: ${({ theme }) => theme.highlight};
    box-shadow: inset 0 0 5px black;
    border: none;
    color: ${({ theme }) => theme['text-2']};
  }
  &.cta-4 {
    background: ${({ theme }) => theme['primary-300']};
    border: none;
    color: ${({ theme }) => theme['greyscale-100']};
  }
  &.outline {
    background: transparent;
    color: ${({ theme }) => (theme.darkmode ? theme['primary-2'] : theme['text-1'])};
    border: 2px solid ${({ theme }) => (theme.darkmode ? theme['primary-2'] : theme['text-1'])};
  }
  &.outline-grey {
    background: transparent;
    border: 2px solid ${({ theme }) => theme['greyscale-200']};
  }
  &.outline-black {
    background: transparent;
    border: 2px solid ${({ theme }) => theme['greyscale-600']};
  }
  &.outline-cta-1 {
    background: ${({ theme }) => (theme.darkmode ? 'transparent' : theme['greyscale-100'])};
    border: 2px solid ${({ theme }) => theme['primary-1']};
    color: ${({ theme }) => (theme.darkmode ? theme['text-1'] : theme['primary-1'])};
  }
  &.outline-cta-1-disabled {
    background-color: ${({ theme }) => theme['greyscale-100']};
    color: ${({ theme }) => theme['greyscale-400']};
    border: 2px solid ${({ theme }) => theme['greyscale-400']};
    &:hover {
      color: ${({ theme }) => theme['greyscale-400']};
      border: 2px solid ${({ theme }) => theme['greyscale-400']};
    }
  }
  &.outline-cta-4 {
    background: ${({ theme }) => theme['greyscale-100']};
    border: 2px solid ${({ theme }) => theme['primary-100']};
    color: ${({ theme }) => theme['primary-1']};
  }
`;
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  id = 'button',
  testId,
  prependContent,
  children,
  appendContent,
  type = BUTTON_TYPE.NONE,
  size = BUTTON_SIZE.MD,
  buttonForm = false,
  isLoading = false,
  onClick = () => {},
  disabled,
  className = '',
  overrideWidth,
  overrideHeight,
  overridePadding,
  requiedSpanWrapBody = true,
  wrapperStyle,
  bouncingInteraction,
}, buttonRef) => {
  const { isMD } = useResponsiveState();

  const sizeConfig:React.CSSProperties = useMemo(() => {
    return {
      ...(buttonForm && { width: isMD ? 196 : 160, height: 40 }),
      ...(overrideWidth && { width: overrideWidth }),
      ...(overrideHeight && { height: overrideHeight }),
      ...(overridePadding && { padding: overridePadding }),
    };
  }, [overrideWidth, overrideHeight, overridePadding, isMD]);
  const typeConfig:ButtonType = useMemo(() => {
    switch (type) {
      case BUTTON_TYPE.NONE:
      case BUTTON_TYPE.PLAIN_TEXT:
        return ANTD_BUTTON_TYPE.LINK;
      case BUTTON_TYPE.CTA_1:
      case BUTTON_TYPE.CTA_2:
      case BUTTON_TYPE.CTA_3:
      case BUTTON_TYPE.CTA_4:
        return ANTD_BUTTON_TYPE.PRIMARY;
      case BUTTON_TYPE.OUTLINE_WHITE:
      case BUTTON_TYPE.OUTLINE_GREY:
      case BUTTON_TYPE.OUTLINE_BLACK:
      case BUTTON_TYPE.OUTLINE_CTA_1:
      case BUTTON_TYPE.OUTLINE_CTA_4:
      case BUTTON_TYPE.OUTLINE_CTA_1_DISABLED:
      default:
        return ANTD_BUTTON_TYPE.DEFAULT;
    }
  }, [type]);
  const handleClick = (e:any) => {
    onClick(e);
  };
  return (
    <StyledAntdButton
      id={id}
      className={classNames(
        className,
        { 'bouncing-interaction': bouncingInteraction },
        type,
      )}
      type={typeConfig}
      disabled={disabled}
      ref={buttonRef}
      onClick={handleClick}
      loading={isLoading}
      style={{
        ...sizeConfig,
        ...wrapperStyle,
      }}
      shape="round"
      size={size as SizeType}
      data-testid={testId}
    >
      {prependContent && (
        <span className={`ps-button__prepend ps-button__prepend--${size}`}>
          {prependContent}
        </span>
      )}
      {requiedSpanWrapBody ? <span>{children}</span> : children}
      {appendContent && (
        <span className={`ps-button__append ps-button__append--${size}`}>
          {appendContent}
        </span>
      )}
    </StyledAntdButton>
  );
});

Button.displayName = 'Button';

export default Button;
export {
  BUTTON_SIZE,
  BUTTON_TYPE,
};
