import { toast, ToastOptions } from 'react-toastify';
import { notification as antdNotification } from 'antd';
import { ArgsProps, NotificationInstance } from 'antd/es/notification/interface';
import font from '@/utils/font';

export enum TOAST_TYPE {
  SYSTEM_STATUS,
  WARN,
  SUCCESS,
  ERROR,
}
const Toast = (
  msg: React.ReactNode,
  optionsProps?: ToastOptions,
  type = TOAST_TYPE.SUCCESS,
  showIcon = true,
) => {
  const options: ToastOptions = {
    ...optionsProps,
    toastId: optionsProps?.toastId || `${type}_${msg}`,
    icon: false,
  };
  switch (type) {
    // TODO : inject custom close button
    case TOAST_TYPE.SYSTEM_STATUS:
      toast.info(
        <div className={`d-flex Toastify__toast--info ${font.className}`}>
          {showIcon && (
            <i
              className="toast-icon ps-icon-info toast-info-icon me-2"
            />
          )}
          {msg}
        </div>,
        options,
      );
      break;
    case TOAST_TYPE.WARN:
      toast.warn(
        <div className={`d-flex Toastify__toast--warning ${font.className}`}>
          {showIcon && (
            <i
              className="toast-icon ps-icon-caution toast-caution-icon me-2"
            />
          )}
          {msg}
        </div>,
        options,
      );
      break;
    case TOAST_TYPE.ERROR:
      toast.error(
        <div className={`d-flex Toastify__toast--error ${font.className}`}>
          {showIcon && (
            <i
              className="toast-icon ps-icon-close-circle toast-cross-icon me-2"
            />
          )}
          {msg}
        </div>,
        options,
      );
      break;
    case TOAST_TYPE.SUCCESS:
    default:
      toast.success(
        <div className={`d-flex Toastify__toast--success ${font.className}`}>
          {showIcon && (
            <i
              className="toast-icon ps-icon-check-stroke toast-check-icon me-2"
            />
          )}
          {msg}
        </div>,
        options,
      );
  }
};

const baseArgs: ArgsProps = {
  message: '',
  placement: 'top',
};

export const notification: NotificationInstance = {
  ...antdNotification,
  success: (args: ArgsProps) => antdNotification.success({ ...baseArgs, ...args, className: font.className }),
  error: (args: ArgsProps) => antdNotification.error({ ...baseArgs, ...args, className: font.className }),
  info: (args: ArgsProps) => antdNotification.info({ ...baseArgs, ...args, className: font.className }),
  warning: (args: ArgsProps) => antdNotification.warning({ ...baseArgs, ...args, className: font.className }),
  open: (args: ArgsProps) => antdNotification.open({ ...baseArgs, ...args, className: font.className }),
};

export const dismissAllToast = () => toast.dismiss();

export default Toast;
