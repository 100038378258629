import { atom } from 'recoil';
import { BigWinChampionDetail } from '@/services/bigWin/getBigWinChampionList.type';
import { KEYS } from '../keys';
import { GameWinnerData } from '@/hooks/useGameWinner';

export interface IncomingWinnerState {
  rtp?: number;
  incomingWinner?: GameWinnerData,
}

export const initialValue: IncomingWinnerState = {
  rtp: undefined,
  incomingWinner: undefined,
};

const incomingWinnerState = atom<IncomingWinnerState>({
  key: KEYS.INCOMING_WINNER,
  default: initialValue,
});

export default incomingWinnerState;
