import { AxiosRequestConfig } from 'axios';
import { useSetRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { BaseApiResponse } from '@/services/service.type';
import { fetchWithJSON } from '@/utils/api/fetch';
import { useAxios } from './useAxios';
import walletSelectorState from '@/recoils/selector';
import { useAuth } from './useAuth';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';

export enum BALANCE_STATUS {
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export type BalanceRequest = {
  userUID: string;
};

export type WalletResponse = BaseApiResponse<{
  user_code: string;
  transaction_uid: string;
  currency_code: string;
  balance_amount: number;
  coin_amount: number;
}>;

export type BalanceResponse = {
  Status: BALANCE_STATUS;
  Message?: string;
  CustomerCode?: string;
  CustomerPhone?: string;
  Balance?: string;
  Coin?: string;
  result?: any;
};
export type HookParams = {
  enableFetchBalance?: boolean;
  fetchBalanceIntervalIn?: number;
};
export type UseBalanceHook = {
  isFetching: boolean;
  callGetBalance: () => void;
  callGetBalancePromise: () => Promise<BalanceResponse>;
};

export const useBalance = (params?: HookParams): UseBalanceHook => {
  const auth = useAuth();
  const setWallet = useSetRecoilState(walletSelectorState);
  const { pigspinApiInstance } = useAxios();

  const callGetBalancePromise = async (): Promise<BalanceResponse> => {
    try {
      if (isEmpty(auth.userUID)) {
        throw new Error();
      }
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/wallet/${auth.userUID}/balance`,
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      };
      const walletResponse = await fetchWithJSON<WalletResponse>(pigspinApiInstance, requestConfig);
      const isSuccess = walletResponse.code === 200;
      const balanceResponse = {
        Status: isSuccess ? BALANCE_STATUS.SUCCESS : BALANCE_STATUS.ERROR,
        Balance: isSuccess ? walletResponse.data.balance_amount.toFixed(2) : '',
        Coin: isSuccess ? walletResponse.data.coin_amount.toFixed(2) : '',
      };
      if (isSuccess) {
        const validateWallet = {
          balance: balanceResponse?.Balance,
          coin: balanceResponse?.Coin,
        };
        setWallet(validateWallet);
      }
      return balanceResponse;
    } catch (err) {
      return {
        Status: BALANCE_STATUS.ERROR,
      };
    }
  };

  const { refetch, isFetching } = useQuery(
    ['callGetBalance'],
    callGetBalancePromise,
    {
      enabled: params?.enableFetchBalance === true,
      refetchInterval: params?.fetchBalanceIntervalIn,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (auth.userUID) refetch();
  }, [auth.userUID]);

  return {
    isFetching,
    callGetBalance: refetch,
    callGetBalancePromise,
  };
};
