import { atom } from 'recoil';

import { KEYS } from '../keys';

export const initialValue = false;

const fetchCoinState = atom<boolean>({
  key: KEYS.FETCH_COIN,
  default: initialValue,
});

export default fetchCoinState;
