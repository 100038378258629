export enum SORT_TYPE {
  GAME_NEW_OLD = 'game-new-to-old',
  GAME_OLD_NEW = 'game-old-to-new',
  RTP_HIGHT_LOW = 'rtp-rate-hight-to-low',
  RTP_LOW_HIGHT = 'rtp-rate-low-to-hight',
  RTPA_HIGHT_LOW = 'rtp-hight-to-low',
  RTPA_LOW_HIGHT = 'rtp-low-to-hight',
  VOLATILITY_HIGHT_LOW = 'volatility-hight-to-low',
  VOLATILITY_LOW_HIGHT = 'volatility-low-to-hight',
  POPULAR = 'play-time',
}

export enum WILD_TYPE {
  EXPANDING = 'expanding-wilds',
  STACKING = 'stacking-wilds',
  MULTIPLIER = 'multiplier-wilds',
  STICKY = 'sticky-wilds',
  TRAVELING = 'traveling-wilds',
  ON_THE_WAY = 'wilds-on-the-way',
  OTHER = 'other',
  NORMAL = 'normal',
  NONE = '',
}

export enum REEL_TYPE {
  ALL = 'all-ways-pay',
  GROUP = 'group',
  OTHER = 'other',
  CLASSIC = 'line-pay',
  INFINITE = 'infinite-reel',
  NONE = '',
}

export enum FUNCTION_TYPE {
  BUY_FEATURE = 'feature_option',
  RTPA_ONLY = 'actual_rtp',
  NONE = '',
}

export enum GAME_COMPANY_TYPE {
  evo = 'EVO',
  playNGo = 'PNG',
  pg = 'PG',
  joker = 'JOKER',
}

export enum CATEGORY_TYPE {
  SLOT = 'SLOT',
  FISHING = 'FISHING',
}
