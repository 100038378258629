import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';

export type JwtTokenData = {
  exp: number;
  phone_number: string;
  user_uid: string;
  game_token: string;
  customer_code: string,
};

const toUnixTimestamp = (time: number) => time * 1000;
const defaultJtwData: JwtTokenData = {
  exp: 0,
  phone_number: '',
  user_uid: '',
  game_token: '',
  customer_code: '',
};

export const toJwtData = (jwtToken: string): JwtTokenData => {
  try {
    const jtwData: JwtTokenData = jwtDecode(jwtToken);
    if (isEmpty(jtwData)) return defaultJtwData;
    return {
      ...jtwData,
      exp: toUnixTimestamp(jtwData.exp),
    };
  } catch {
    return defaultJtwData;
  }
};

export const purifyToken = (jwtToken: string): string => {
  return jwtToken.replace('bearer', '').replace('%20', '').trim();
};

export const decodeAuthToken = (jwtToken: string): JwtTokenData => {
  const jwtData: JwtTokenData = toJwtData(purifyToken(jwtToken));
  return jwtData;
};
