import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { GameBrandState } from '@/models/shared-types';
import { KEYS } from '../keys';

const { persistAtom } = recoilPersist();

export const initialValue: GameBrandState = {
  list: [],
  obj: {},
};

const gameBrandStateAtom = atom<GameBrandState>({
  key: KEYS.GAME_BRAND,
  default: initialValue,
  effects_UNSTABLE: [persistAtom],
});

export default gameBrandStateAtom;
