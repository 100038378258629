import { atom, selector } from 'recoil';
import { KEYS } from '../keys';

export type InboxItem = {
  uid: string;
  userUid: string;
  title: string;
  subTitle: string;
  content?: string;
  type: 'transaction' | 'affiliate' | 'system';
  icon?: string;
  date: Date;
  isRead: boolean;
  start?: Date;
  end?: Date;
  createdAt: Date;
  updatedAt: Date;
  productType?: string;
};

export type InboxState = {
  inboxs: InboxItem[];
  unread: number;
};

export type InboxAll = {
  systemStatus: InboxState;
  transaction: InboxState;
  affiliate: InboxState;
  allUnread: number;
};

export const defaultInboxState: InboxState = {
  inboxs: [],
  unread: 0,
};

export const inboxSystemStatusState = atom<InboxState>({
  key: KEYS.INBOX_SYSTEM_STATUS,
  default: defaultInboxState,
});

export const inboxTransactionState = atom<InboxState>({
  key: KEYS.INBOX_TRANSACTION,
  default: defaultInboxState,
});

export const inboxAffiliateState = atom<InboxState>({
  key: KEYS.INBOX_AFFILIATE,
  default: defaultInboxState,
});

const countUnread = (items: InboxItem[]): number => {
  return items.filter((i) => !i.isRead).length;
};

export const allInboxSelector = selector<InboxAll>({
  key: KEYS.INBOX_ALL,
  get: ({ get }) => {
    const systemStatus = get(inboxSystemStatusState);
    const transaction = get(inboxTransactionState);
    const affiliate = get(inboxAffiliateState);
    const unreadSystemStatus = 0; // NOTE : handle on FE
    const unreadTransaction = countUnread(transaction.inboxs);
    const unreadAffiliate = countUnread(affiliate.inboxs);
    return {
      allUnread: unreadSystemStatus + unreadTransaction + unreadAffiliate,
      systemStatus: {
        inboxs: systemStatus.inboxs,
        unread: 0, // unreadSystemStatus, NOTE : cannot use this because FE have to check read from cookies
      },
      transaction: {
        inboxs: transaction.inboxs,
        unread: unreadTransaction,
      },
      affiliate: {
        inboxs: affiliate.inboxs,
        unread: unreadAffiliate,
      },
    };
  },
});
