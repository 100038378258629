import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  API_URL,
  BO_API_URL,
  PIGSPIN_API_URL,
  QR_PAYMENT_API_URL,
} from '@/models/buildtime-constant';
import { BaseApiResponse } from '@/services/service.type';
import { useAuth } from './useAuth';

const handleResponse = (response: AxiosResponse<any>) => response;
const handleErrorResponse = (error: AxiosError<BaseApiResponse<any>>) => error.response;

export const useAxios = () => {
  const auth = useAuth();
  const phpApiInstance = axios.create({
    baseURL: API_URL,
    // timeout: 5000,
  });

  const boApiInstance = axios.create({
    baseURL: BO_API_URL,
    headers: {
      authorisation: auth.cfid,
    },
  });
  boApiInstance.interceptors.response.use<any>(handleResponse, handleErrorResponse);

  const pigspinApiInstance = axios.create({
    baseURL: PIGSPIN_API_URL,
    headers: {
      authorisation: auth.cfid,
    },
    // timeout: 5000,
  });
  pigspinApiInstance.interceptors.response.use<any>(handleResponse, handleErrorResponse);

  const qrPaymentApiInstance = axios.create({
    baseURL: QR_PAYMENT_API_URL,
    // timeout: 7500,
  });
  qrPaymentApiInstance.interceptors.response.use<any>(handleResponse, handleErrorResponse);
  return {
    phpApiInstance,
    boApiInstance,
    pigspinApiInstance,
    qrPaymentApiInstance,
  };
};
