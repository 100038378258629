import isEmpty from 'lodash/isEmpty';

const VerifiedRefType = (refType:string):string => {
  if (isEmpty(refType)) return refType || '';
  if (['pg_games'].includes(refType)) return refType.replace('_games', '').trim();
  if (['PG_GAME'].includes(refType)) return refType.replace('_GAME', '').trim();
  return refType.trim();
};

export default VerifiedRefType;
