import { atom } from 'recoil';

import { KEYS } from '../keys';

export const initialValue = false;

const onInputFocusState = atom({
  key: KEYS.KEYBOARD_FOCUS,
  default: initialValue,
});

export default onInputFocusState;
