export const breakPointsNumber = {
  xs: 320,
  sm: 375,
  md: 768,
  lg: 1024,
  xl: 1440,
};

export const breakPoints = {
  xs: `${breakPointsNumber.xs}px`,
  sm: `${breakPointsNumber.sm + 1}px`,
  md: `${breakPointsNumber.md}px`,
  lg: `${breakPointsNumber.lg}px`,
  xl: `${breakPointsNumber.xl}px`,
};

export const screen = {
  xs: `(min-width: ${breakPoints.xs})`,
  sm: `(min-width: ${breakPoints.sm})`,
  md: `(min-width: ${breakPoints.md})`,
  lg: `(min-width: ${breakPoints.lg})`,
  xl: `(min-width: ${breakPoints.xl})`,
};
