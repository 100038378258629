import { atom } from 'recoil';

import { KEYS } from '../keys';

export interface IWalletState {
  total: string;
  balance: string;
  /**
   * @deprecated soon : use bonus.ts instread
   */
  coin: string;
}

export const initialValue: IWalletState = {
  total: '',
  balance: '',
  coin: '',
};

const walletState = atom<IWalletState | undefined>({
  key: KEYS.WALLET,
  default: initialValue,
});

export default walletState;
