import React from 'react';
import { motion } from 'framer-motion';
import { Loading } from '@/components/Loading';

type LoadMoreButtonProps = {
  isLoading?: boolean;
  isNoMore?: boolean;
  loadMoreText: string;
  noMoreText: string;
  onLoadMore?: () => void;
};

const LoadMoreButton = React.memo(({
  isLoading,
  isNoMore,
  loadMoreText,
  noMoreText,
  onLoadMore,
}: LoadMoreButtonProps) => {
  return (
    <>
      <>
        {isNoMore ? (
          <div className="ps-champion-load-more ps-champion-load-more--ended">
            {noMoreText}
          </div>
        ) : (!isLoading) ? (
          <></>
        ) : (
          <div className="ps-champion-load-more">
            <motion.div
              key="motion-qr-loading"
              id="wallet-qr-deposit-loader"
              className="ps-deposit-qr__loader"
            >
              <Loading />
            </motion.div>
          </div>
        )}
      </>
    </>
  );
});

export default LoadMoreButton;
