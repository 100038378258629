import { atom } from 'recoil';

import { KEYS } from '../keys';
import { CoinWalletResultType } from '../type';

export type BonusState = {
  activeBonus: CoinWalletResultType[];
  completedBonus: CoinWalletResultType[];
};

export const initialValue: BonusState = {
  activeBonus: [],
  completedBonus: [],
};

const bonusState = atom<BonusState>({
  key: KEYS.BONUS,
  default: initialValue,
});

export default bonusState;
