import { atom } from 'recoil';

import { KEYS } from '../keys';

export enum FAQ_TAB {
  GENERAL = 'general',
  QUEST = 'questQuestions',
  INVITE_FRIEND = 'inviteFriend',
}

type FaqState = {
  selectedTab: FAQ_TAB;
};

export const initialValue: Partial<FaqState> = {
  selectedTab: FAQ_TAB.GENERAL,
};

const faqState = atom<Partial<FaqState>>({
  key: KEYS.FAQ,
  default: initialValue,
});

export default faqState;
