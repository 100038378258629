import { atom } from 'recoil';

import { KEYS } from '../keys';

type PigShopState = {
  primaryTabIndex: number | undefined;
  secondaryPhysicalTabIndex: number | undefined;
};

export const initialValue: Partial<PigShopState> = {
  primaryTabIndex: undefined,
  secondaryPhysicalTabIndex: undefined,
};

const pigShopState = atom<Partial<PigShopState>>({
  key: KEYS.PIG_SHOP,
  default: initialValue,
});

export default pigShopState;
