import { atom } from 'recoil';

import { KEYS } from '../keys';

export const initialValue = {
  channels: ['channel-public'],
  currentChannel: 'channel-public',
  unreadMessage: {
    'channel-public': 0,
  },
  unreadMessageTotal: 0,
  isChatBoxActive: false,
  forceReconnection: false,
};
type RecoilChat = {
  channels: string[],
  currentChannel: string,
  unreadMessage: {
    [channel: string]: number,
  };
  unreadMessageTotal: number,
  forceReconnection?: boolean,
  isChatBoxActive: boolean,
};

const recoilChat = atom<RecoilChat>({
  key: KEYS.CHAT_CHANNEL,
  default: initialValue,
});

export default recoilChat;
