import { atom } from 'recoil';

import { KEYS } from '../keys';

export enum FEATURE_TOGGLE {
  OFF = 0,
  ON = 1,
}

export type featureToggleStateType = {
  [feature: string]: FEATURE_TOGGLE;
};

// NOTE: if you want also toggle the hamburger item,
// named the feature toggle variable as the same hamburger name

export const initialValue: featureToggleStateType = {
  dailyQuest: FEATURE_TOGGLE.ON,
  pigShop: FEATURE_TOGGLE.ON,
};

const featureToggleState = atom<featureToggleStateType>({
  key: KEYS.FEATURE_TOGGLE,
  default: initialValue,
});

export default featureToggleState;
