import { atom } from 'recoil';

import { KEYS } from '../keys';
import { GameItem } from '@/services/games/games.type';

export const initialValue = [];

const favoriteGameState = atom<GameItem[]>({
  key: KEYS.FAVORITE_GAME,
  default: initialValue,
});

export default favoriteGameState;
