import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { SORT_TYPE } from '@/components/Search/Search.type';
import { KEYS } from '../keys';

const { persistAtom } = recoilPersist();

export type SortConfigState = SORT_TYPE;

export const initialValue: SortConfigState = SORT_TYPE.POPULAR;

const sortConfigState = atom<SortConfigState>({
  key: KEYS.SORT_CONFIG,
  default: initialValue,
  effects_UNSTABLE: [persistAtom],
});

export default sortConfigState;
