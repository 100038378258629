import { selector } from 'recoil';
import walletState, {
  initialValue as walletInitialValue,
} from './modules/wallet';
import gameState, { initialValue as gameInitivalValue } from './modules/game';
import gameBrandStateAtom, { initialValue as gameBrandInitivalValue } from './modules/game-brand';
import registerState, {
  initialValue as registerInitialValue,
} from './modules/register';
import coinState, { initialValue as coinInitialValue } from './modules/coin';
import {
  systemStatusState,
  systemStatusInitialValue,
  systemStatusToastState,
  systemStatusToastInitialValue,
} from './modules/system-status';
import RankingGameState, {
  initialValue as rankingGameInitialValue,
} from './modules/ranking-game';
import fetchCoinState, {
  initialValue as fetchCoinInitialValue,
} from './modules/fetch-wallet';
import profileState, {
  initialValue as profileInitialValue,
} from './modules/profile';
import featureToggleState, {
  initialValue as featureToggleInitialValue,
} from './modules/feature-toggle';
import faqState, { initialValue as faqInitialValue } from './modules/faq';
import pigShopState, {
  initialValue as pigShopInitialValue,
} from './modules/pig-shop';
import onInputFocusState, {
  initialValue as onInputFocusInitialValue,
} from './modules/onInputFocus';
import tutorialModal, { initialValue as tutorialInitialValue } from './modules/tutorialModal';
import uiVariant, {
  initialValue as uiVariantInitialValue,
} from './modules/ui-variant';
import favoriteGameState, {
  initialValue as favoriteGameStateInitialValue,
} from './modules/favorite-game';
import recoilChat, {
  initialValue as defaultRecoilChat,
} from './modules/chat-channel';
import sortConfigState, {
  initialValue as defaultSortConfigValue,
} from './modules/sort-config';
import filterConfigState, {
  initialValue as defaultFilterConfigValue,
} from './modules/filter-config';
import notificationState, {
  initialValue as defaultNotificationState,
} from './modules/notification';
import {
  defaultInboxState,
  inboxAffiliateState,
  inboxSystemStatusState,
  inboxTransactionState,
} from './modules/inbox';

import recoilAction from './action';
import incomingBigWinState, {
  initialValue as defaultincomingBigWinState,
} from './modules/vip';
import vipState, {
  initialValue as defaultVipState,
} from './modules/bigwin';

const clearAllState = selector({
  key: 'allStateSelector',
  set: ({ set }, newValue?: any) => {
    // Clear all state
    set(walletState, walletInitialValue);
    set(gameState, gameInitivalValue);
    set(gameBrandStateAtom, gameBrandInitivalValue);
    set(registerState, registerInitialValue);
    set(coinState, coinInitialValue);
    set(systemStatusState, systemStatusInitialValue);
    set(systemStatusToastState, systemStatusToastInitialValue);
    set(onInputFocusState, onInputFocusInitialValue);
    set(RankingGameState, rankingGameInitialValue);
    set(fetchCoinState, fetchCoinInitialValue);
    set(profileState, profileInitialValue);
    set(featureToggleState, featureToggleInitialValue);
    set(faqState, faqInitialValue);
    set(tutorialModal, tutorialInitialValue);
    set(pigShopState, pigShopInitialValue);
    set(uiVariant, uiVariantInitialValue);
    set(favoriteGameState, favoriteGameStateInitialValue);
    set(recoilChat, defaultRecoilChat);
    set(sortConfigState, defaultSortConfigValue);
    set(filterConfigState, defaultFilterConfigValue);
    set(inboxSystemStatusState, defaultInboxState);
    set(inboxTransactionState, defaultInboxState);
    set(vipState, defaultVipState);
    set(inboxAffiliateState, defaultInboxState);
    set(notificationState, defaultNotificationState);
    set(incomingBigWinState, defaultincomingBigWinState);
    recoilAction.removeByKey('AUTH');
  },
  get: ({ get }) => {
    return {
      walletState,
      gameState,
      registerState,
      coinState,
      systemStatusState,
      systemStatusToastState,
      onInputFocusState,
      RankingGameState,
      fetchCoinState,
      profileState,
      featureToggleState,
      faqState,
      pigShopState,
      uiVariant,
      favoriteGameState,
      notificationState,
      vipState,
      incomingBigWinState,
    };
  },
});

export default clearAllState;
