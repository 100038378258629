import { atom } from 'recoil';
import { KEYS } from '../keys';

export const initialValue = 'v1';

const uiVariant = atom<string>({
  key: KEYS.UI_LANDING_VARIANT,
  default: 'v1',
});

export default uiVariant;
